<template>
  <v-row justify="center">
      <v-dialog
        v-model="showDialog"
        scrollable
      >
        <v-card>
          <v-card-title>Begründung</v-card-title>
          <v-card-text>
            Bitte gibt an warum du die Stunde löschst.

            <v-form @submit.prevent v-model="valid">
                <v-textarea
                outlined
                auto-grow
                counter="200"
                :rules="counterRule"
                background-color="accent"
                label="Begründung"
                v-model.trim="reasonForDeletion"
                ></v-textarea>
            </v-form>
            
          </v-card-text>
          <v-card-actions>
            <v-container>
              <v-row class="mb-2">
                <v-btn
                  @click="cancel"
                  color="secondary"
                  width="100%"
                >
                  Abbrechen
                </v-btn>
              </v-row>
              <v-row>
                <v-btn
                  @click="deleteEvent"
                  :disabled="!valid"
                  color="error"
                  width="100%"
                >
                  Stunde löschen
                </v-btn>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-dialog>
      </v-row>
</template>

<script>
export default {
  name: 'DialogNoteLesssonAccount',
  props: ['showNoteDialog'],

  data() {
    return{
      showDialog: false,
      valid: false,
      counterRule: [
        v => (v || '' ).length <= 200 && !!v || 'Gib maximal 200 Zeichen ein'
      ],
      reasonForDeletion: "",
    }
  },

  watch: {
    showNoteDialog() {
      this.showDialog = this.showNoteDialog;
    },
  }, 

  methods: {
    deleteEvent(){
      /* emits action to the parent, so that the function "addSeries" in the parent is called */
      this.$emit('deleteEvent', this.reasonForDeletion);
    },

    cancel() {
      this.showDialog = false
      this.$emit('cancelDeletingEvent')
    },
  }
}
</script>

<style>

</style>