<template>
  <div>
  <v-card 
  max-width="370" 
  class="mx-auto mt-5" 
  flat
  v-touch="{
    left: () => redirectToEventInfo(nextEventId),
    right: () => redirectToEventInfo(lastEventId),
  }"
  >
    <Errors v-bind:errors="errors"/>

    <!-- Dialog for Conflicts when adding a series -->
    <DialogNoteLessonAccount 
      v-bind:showNoteDialog="this.showNoteDialog" 
      @cancelDeletingEvent=cancelDeletingEvent
      @deleteEvent=deleteEvent
    />
    <div>
      <v-card-text>
        <table>
          <tr>
            <td>
              Stunde am <b>{{ event.date | formatDateWithWeekday }}</b> von <b>{{ event.start }} - {{ event.end }}</b>, {{event.room}} 
            </td>
            <td>
              <v-icon color="primary" @click="$router.push('/overview/' + event.date)">mdi-newspaper-minus</v-icon>
            </td>
            <td>
              <v-icon color="primary" @click="showInRoomPlan">
                mdi-calendar-multiselect
              </v-icon>
            </td>
          </tr>
        </table>
      </v-card-text>

      <v-card-title 
      class="pt-0 text-h5 font-weight-medium"
      @click="$router.push('/student/' + event.studentRef.id)"
      >
          <i>{{ student }}</i>
      </v-card-title>
    
      <v-card-subtitle
      @click="$router.push('/teacher/' + event.teacherRef.id)"
      >
          Lehrer: <b>{{ teacher }}</b> 
      </v-card-subtitle>
      
    </div>
    

    <v-card-text>
      <v-form @submit.prevent v-model="valid">

      <v-textarea
      outlined
      auto-grow
      counter="200"
      :rules="counterRule"
      background-color="accent"
      label="Stundennotizen"
      v-model.trim="notes"
      ></v-textarea>

      <v-switch
      inset
      label="Zu spät"
      v-model="toLate"
      ></v-switch>

      <v-switch
      inset
      label="Hausaufgaben nicht gemacht"
      v-model="noHomework"
      ></v-switch>

      </v-form>
    </v-card-text>

    <v-card-actions class=" pt-0">
      <v-spacer/>
      <v-btn
        @click="saveEventNotes"
        :disabled="!valid"
        color="primary"
        depressed>
        Speichern <!-- TODO kein speicher button nötig, autosave. ggf bei destroy speichern? -->
      </v-btn>
    </v-card-actions>
    
    <v-card-actions>
      
      <v-container>
        
        <v-row class="mt-2">
          <table style="margin: auto;">
            <tr>
              <td v-if="lastEventId" style="width: fit-content">
                <v-icon @click="redirectToEventInfo(lastEventId)">
                  mdi-chevron-left
                </v-icon>
              </td>

              <td>
                <v-btn 
                v-if="event.seriesReference != ''"
                width="100%"
                color="secondary"
                @click="$router.push('/series/' + event.seriesReference.id)"
                depressed>
                  Events aus dieser Serie
                </v-btn>
              </td>

              <td v-if="nextEventId" style="width: fit-content">
                <v-icon @click="redirectToEventInfo(nextEventId)">
                  mdi-chevron-right
                </v-icon>
              </td>
            </tr>
          </table>
        </v-row>

        <v-row class="my-2 mt-8">
            <v-btn
            width="100%"
            color="primary"
            depressed
            @click="editEvent">
              Ändern
            </v-btn>
        </v-row>
        <v-row v-if="isAdmin" class="my-2">
            <v-btn 
            v-if="event.seriesReference != ''"
            width="100%"
            color="primary"
            @click="$router.push('/editseries/' + event.seriesReference.id)"
            depressed>
              Serie ändern
            </v-btn>
        </v-row>

        <v-row class="my-2 mt-8">
            <v-btn
            width="100%"
            color="error"
            depressed
            @click="showNoteDialog = true;">
                Löschen
            </v-btn>

            <v-card-subtitle class="ma-0 pa-0">        
              Wir schreiben diese Stunde dann automatisch auf 
              <router-link v-if="studentId" :to="{name: 'LessonAccount', params: { studentId: studentId } }">
                  {{student}}s Stundenkonto 
              </router-link> gut.
            </v-card-subtitle>
        </v-row>

        
      </v-container>

    </v-card-actions>
  </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { eventsCollection} from '@/firebase';
import {calculateTimeBetween, formatDate} from '@/assets/js/timeUtils.js'
import Errors from '@/components/Errors.vue';
import DialogNoteLessonAccount from '@/components/DialogNoteLessonAccount.vue';

export default {
  name: 'InfoEvent',

  props: {
    routeEventId: { //got from route
      type: String
    }
  },

  components: {
    Errors,
    DialogNoteLessonAccount
  },

  data() {
    return {
      eventId: this.routeEventId, //will be set to routeEventId in a watcher
      event: {'seriesReference': ''}, //I initialized seriesReference here to avoid that the buttons with v-if !='' are displayed until the data is loaded
      teacher: '',
      student: '',
      studentId: '',
      errors: [],
      info: '',
      toLate: false,
      noHomework: false,
      notes: '',
      nextEventId: null,
      lastEventId: null,
      counterRule: [
        v => (v || '' ).length <= 200 || 'Gib maximal 200 Zeichen ein'
      ],
      valid: false,
      showNoteDialog: false,
    }
  },

  computed: {
    ...mapState(['isAdmin']),
  },

  watch: {
    //we need this watchers to also load new events when clicking on "back" in the browser
    routeEventId: function() {
      this.eventId = this.routeEventId
      this.fetchEvent()
    },

  },

  async created() {
    await this.fetchEvent();
    this.getTeacherName();
    this.getStudentName();
  },

  methods: {
    async fetchEvent() {
      try {
        const eventDoc = await eventsCollection.doc(this.eventId).get();
        this.event = eventDoc.data();
        this.event.id = eventDoc.id;

        if(this.event.notes){
          this.notes = this.event.notes;
        }

        if(this.event.toLate){
          this.toLate = this.event.toLate;
        }

        if(this.event.noHomework){
          this.noHomework = this.event.noHomework;
        }

        /* get the series to this event to determine the next and previous event */
        if(this.event.seriesReference != ''){
          try {
            var doc = await this.event.seriesReference.get();
            var eventReferences = doc.data().eventReferences;
            const indexOfCurrentEvent = eventReferences.indexOf(eventReferences.find(element => element.id == this.eventId))
            if(indexOfCurrentEvent + 1 <= eventReferences.length - 1){
              this.nextEventId = eventReferences[indexOfCurrentEvent + 1].id
            } else {
              this.nextEventId = null;
            }
            if(indexOfCurrentEvent - 1 >= 0){
              this.lastEventId = eventReferences[indexOfCurrentEvent - 1].id
            } else {
              this.lastEventId = null;
            }
          } catch {
            this.errors.push({
              text: 'Die Serie zu diesem Event konnte nicht geladen werden.', 
              type:'firestore',
            })
            // scroll to beginning of page to display warning
            window.scrollTo(0,0);
            throw "Could not find this doument in firestore";
          } 
        }

      } catch {
        this.errors.push({
          text: 'Das Event konnte nicht aus der Datenbank geladen werden.', 
          type:'firestore',
        })
        // scroll to beginning of page to display warning
        window.scrollTo(0,0);
        throw "Could not find this doument in firestore";
      }
      
    },

    async getTeacherName() {
      try {
        const teacherdoc = await this.event.teacherRef.get();
        this.teacher = teacherdoc.data().firstname + " " + teacherdoc.data().lastname;
      } catch { 
        this.errors.push({
          text: 'Die Lehrerin / der Lehrer konnte nicht aus der Datenbank geladen werden.', 
          type:'firestore',
        })
        // scroll to beginning of page to display warning
        window.scrollTo(0,0);
        throw "Could not find this doument in firestore";
      }
    },

    async getStudentName() {
      const studentDoc = await this.event.studentRef.get();
      if (studentDoc.exists) {
        this.student = studentDoc.data().firstname + " " + studentDoc.data().lastname;
        this.studentId = studentDoc.id;
      } else { 
        this.errors.push({
          text: 'Die Schülerin / der Schüler konnte nicht aus der Datenbank geladen werden.', 
          type:'firestore',
        })
        throw "Could not find this doument in firestore";
      }
    },

    saveEventNotes() {
      if(this.valid){
        try{
          //update everything in teachersCollection
          eventsCollection
            .doc(this.event.id)
            .update({
              notes: this.notes,
              toLate: this.toLate,
              noHomework: this.noHomework,
          })

        } catch(error) {
          console.log("Error updating documents: ", error.message);
          this.errors.push({
            text: 'Die Informationen konnten nicht gespeichert werden.', 
            type:'firestore',
          });
          window.scrollTo(0,0);
        }
      }
    },

    /**
     * @description delete the displayed event in firestore and remove its reference in seriesReferences
     */
    async deleteEvent(reasonForDeletion){
      this.showNoteDialog = false;
      try {

        /* if this event is part of a series, delete its reference */
        if(this.event.seriesReference != ''){
          try {
            var doc = await this.event.seriesReference.get();
            var eventReferences = doc.data().eventReferences;
            var updatedEventReferences = []; //array of all eventReferences except for the one we want to delete
            for(let i=0; i < eventReferences.length; i++){
              if(eventReferences[i].id != this.eventId){
                updatedEventReferences.push(eventReferences[i])
              }
            }
            this.event.seriesReference.update({
              eventReferences: updatedEventReferences
            })
          } catch {
            this.errors.push({
              text: 'Das Event konnte nicht gelöscht werden.', 
              type:'firestore',
            })
            // scroll to beginning of page to display warning
            window.scrollTo(0,0);
            throw "Could not find this doument in firestore";
          } 
        }

        /* delete the event */
        await eventsCollection.doc(this.eventId).delete();

        /* add the hour to the hour account */
        let today = formatDate(new Date().toISOString().substr(0,10));
        await this.$store.dispatch('editLessonAccount', {
          studentId: this.studentId, 
          minutes: calculateTimeBetween(this.event.start, this.event.end), 
          notes: reasonForDeletion + ' [Stunde wurde am ' + today + ' gelöscht.]', 
          date: this.event.date,
          subject: this.event.subject, 
          teacherName: this.teacher,
        })

        this.$router.push('/raumplan/' + this.event.date + '/' + this.event.roomId);

      } catch {
        //todo
      }
    },

    cancelDeletingEvent() {
      this.showNoteDialog = false;
    },
    
    /**
     * @description to edit a event, one is redirected to editEvent with the information of this event prefilled
     * @todo reuses prefillAddEvent in store. and is very similar to addEvent
     */
    editEvent(){
      /* redirect to addEvent */
      this.$router.push('/editevent/' + this.eventId)
    },

    showInRoomPlan(){
      this.$router.push({
        name: 'Raumplan', 
        params: {
          routeDate: this.event.date,
          routeRoom: this.event.roomId,
        }
      })
    },

    redirectToEventInfo(eventId) {
      
        /* redirect to EventInfo view and pass with it the id of the event */
        if(eventId){
          this.nextEventId = null;
          this.lastEventId = null;
          this.$router.push('/event/' + eventId);
        } else {
          console.log('No next/previous event in this series.')
        }
      },
  }
  
}
</script>

<style>
/* workarround for bugfix: on iOS the cursor is invisible when first clicking into the field, see https://github.com/vuetifyjs/vuetify/issues/13628 */
.v-text-field > .v-input__control > .v-input__slot {
  transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
</style>